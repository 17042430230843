import React, { useEffect } from 'react';
import Footer from '../Footer';
import Header from '../Header';
// import dynamic from 'next/dynamic';

export default function Layout({ headerVariant, children }) {
  return (
    <>
      <Header variant={headerVariant} />
      {children}
      <Footer />
    </>
  );
}
