// import Head from "next/head";
import { Helmet } from "react-helmet";

import Card from "../../components/Card/index";
import Cta from "../../components/Cta/index";
import FunFact from "../../components/FunFact/index";
import Hero from "../../components/Hero/index";
import Layout from "../../components/Layout/index";
// import LogoList from "../components/LogoList";
import MovingText from "../../components/MovingText/index";
import SectionHeading from "../../components/SectionHeading/index";
// import PortfolioSlider from "../components/Slider/PortfolioSlider";
import Spacing from "../../components/Spacing/index";
import ReactHtmlParser from "react-html-parser";
import axios from "axios"; // Axios'ı ekleyin

import { useEffect, useState } from "react";

// import product from "../components/json/product.json";

export default function Home() {
  const [products, setProducts] = useState([]); // Ürünleri saklayacak bir state oluşturun

  function slugify(text) {
    return text
      .toLowerCase()
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c")
      .replace(/\s+/g, "-") // Boşlukları tireye dönüştürür
      .replace(/[^-\w]+/g, "") // Alfanumerik olmayan karakterleri kaldırır
      .replace(/-+/g, "-") // Birden fazla tireyi tek bir tireye dönüştürür
      .replace(/^-+/, "") // Başlangıçtaki tireleri kaldırır
      .replace(/-+$/, ""); // Sondaki tireleri kaldırır
  }

  function removeTurkishChars(text) {
    const turkishChars = {
      'ğ': 'g',
      'Ğ': 'G',
      'ü': 'u',
      'Ü': 'U',
      'ş': 's',
      'Ş': 'S',
      'ı': 'i',
      'İ': 'I',
      'ö': 'o',
      'Ö': 'O',
      'ç': 'c',
      'Ç': 'C'
    };
  
    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, match => turkishChars[match]);
  }
  useEffect(() => {
    // Verileri çekmek için useEffect kullanın
    axios
      .get("https://api.aynaart.com.tr/productaynaart")
      .then((response) => {
        setProducts(response.data);
        // console.log("res" + response.data); // Çekilen verileri state'e kaydedin
      })
      .catch((error) => {
        console.error("Ürünleri çekerken hata oluştu:", error);
      });
  }, []);

  // Hero Social as
  const heroSocialas = [
    {
      name: "Instagram",
      as: "https://www.instagram.com/aynaart_/",
    },
    {
      name: "Facebook",
      as: "https://www.facebook.com/profile.php?id=100063608101000",
    },
  ];

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "instant" });
    }, 1);
  }, []);

  return (
    <>
     
      <Helmet>
        <title>Aynaart</title>
        <meta name="description" content="Aynaların sıradan bir objeden daha fazlası olduğuna inanıyoruz. Her ayna, bir sanat eseri gibi özenle tasarlanır ve ruhunuza hitap etmek için yaratıcılığı yansıtır. Özgün tasarımlarımızla, mekanlarınıza karakter, tarz ve kişilik katmayı hedefliyoruz."/>
        <link
          rel="canonical"
          href={"https://www.aynaart.com.tr/"}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Layout>
        {/* Start Hero Section */}
        <Hero
          title="Tarzınızın <br/>Yansıması"
          subtitle="Estetik açıdan çarpıcı tasarımlarımız ve yüksek kalite standartlarımızla, yaşam alanlarınıza parlaklık ve zarafet katmak için buradayız."
          btnText="Ürünlerimiz"
          btnLink="/urunler"
          scrollDownId="#urunler"
          socialasHeading="Bizi Takip Edin"
          heroSocialas={heroSocialas}
          bgImageUrl="/images/ayna.jpg"
        />
        {/* End Hero Section */}

        {/* Start FunFact Section */}
        <div className="container">
          <FunFact
            variant="cs-type1"
            title=" Mekanlarınıza Özgünlük Katıyoruz"
            subtitle="Aynaların sıradan bir objeden daha fazlası olduğuna inanıyoruz. Her ayna, bir sanat eseri gibi özenle tasarlanır ve ruhunuza hitap etmek için yaratıcılığı yansıtır. Özgün tasarımlarımızla, mekanlarınıza karakter, tarz ve kişilik katmayı hedefliyoruz."
            /* data= {funfaceData} */
          />
        </div>
        {/* End FunFact Section */}

        {/* Start Service Section */}
        <div id="urunler" className="mb-3"></div>
        <Spacing lg="150" md="80" />

        <div>
          <div className="container">
            <div className="row">
              <div className="col-xl-4 black_text">
                <SectionHeading
                  title="Bazı Ürünlerimiz"
                  subtitle="Aynaart"
                  btnText="Tüm Ürünleri Gör"
                  btnLink="/urunler"
                />
                <Spacing lg="90" md="45" />
              </div>

              <div className="col-xl-8">
                <div className="row">
                  {products.length > 0 &&
                    products.slice(0, 3).map((item) => (
                      <div className="col-lg-4 col-md-4" key={item.id}>
                        <a  href={`/urunler/${removeTurkishChars(item.category.name).replace(/\s+/g, '-').toLowerCase()}`}>
                          <Card
                            title={item.category.name}
                            info={item.category.info}
                            src={`https://api.aynaart.com.tr/uploads/${item.category.img}`}
                            data={item}
                            id={item._id}
                          />
                        </a>

                        <Spacing lg="30" md="30" />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*       </div>
          </div>
        </div> */}
        {/* End Service Section */}

        {/* Start MovingText Section */}
        <Spacing lg="125" md="70" />
        <MovingText text="Aynaart | Aynaart | Aynaart | " />
        <Spacing lg="105" md="70" />
        {/* End MovingText Section */}

        {/* Start CTA Section */}
        <div className="container">
          <Cta
            title="Daha Fazla Bilgi İçin İletişime Geçin"
            btnText="İletişime Geçin"
            btna="/iletisim"
            /*  bgSrc="/images/cta_bg.jpeg" */
          />
        </div>
        {/* End CTA Section */}
      </Layout>
    </>
  );
}
