import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Card from "../../components/Card";
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading";
import Spacing from "../../components/Spacing";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

function KategoriDetails() {
  const { kategoriAyrinti } = useParams();
  const [category, setCategory] = useState({
    name: "",
    desc: "",
    info: "",
    key: ""
  });

  
  const [products, setProducts] = useState([]);

  const navigate = useNavigate(); 

  const handleProductClick = (productName) => {
    navigate(`/urunAyrinti/${productName.replace(/\s+/g, '-').toLowerCase()}`);
  };
  
  function removeTurkishChars(text) {
    const turkishChars = {
      'ğ': 'g',
      'Ğ': 'G',
      'ü': 'u',
      'Ü': 'U',
      'ş': 's',
      'Ş': 'S',
      'ı': 'i',
      'İ': 'I',
      'ö': 'o',
      'Ö': 'O',
      'ç': 'c',
      'Ç': 'C'
    };
  
    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, match => turkishChars[match]);
  }
  useEffect(() => {
    axios
      .get("https://api.aynaart.com.tr/productaynaart")
      .then((response) => {
        const filteredCategory = response.data.find((item) => {
          return (
            removeTurkishChars(item.category.name).toLowerCase() ===
            kategoriAyrinti.toLowerCase().replace(/-/g, " ")
            
          );
        });

        if (filteredCategory && filteredCategory.category) {
          const categoryData = filteredCategory.category;
          setCategory({
            name: categoryData.name,
            desc: categoryData.metaDesc,
            info: categoryData.info,
            key: categoryData.metaKeywords
          });
          if (categoryData.products) {
            setProducts(categoryData.products);
          } else {
            setProducts([]);
          }
        }
      })
      .catch((error) => {
        // console.error("Ürünleri çekerken hata oluştu:", error);
      });
    
  }, [kategoriAyrinti]);



  return (
    <>
      <Helmet>
        <title>Aynaart | {category.name} </title>
        <meta name="description" content={category.desc} />
        <meta name="keywords" content={category.key} />
        <link rel="icon" href="/favicon.ico" /> <link
          rel="canonical"
          href={`https://www.aynaart.com.tr/urunler/${removeTurkishChars(category.name).replace(/\s+/g, '-').toLowerCase()}`}
        />
      </Helmet>
      <Layout>
        <PageHeading
          title={category.name}
          bgSrc="/images/blog_hero_bg.jpeg"
          pageLinkText={category.name}
        />

        <Spacing lg="100" md="80" />
        <div className="container aligner">
          <div className="row d-flex justify-content-center align_responsive">
            {products.length > 0 ? (
              products.map((item, index) => (
               
                <div className="col-lg-4 col-md-8" key={item._id}>
                   
               <div onClick={() => handleProductClick(item.name)}>
                <Card
                  title={item.name}
                  src={`https://api.aynaart.com.tr/uploads/${item.img[0]}`}
                  data={item}
                  link={`/urunAyrinti/${removeTurkishChars(item.name).replace(/\s+/g, '-').toLowerCase()}`}
                  id={item._id}
                />
              </div>
                </div>
              ))
            ) : (
              <p className="text-black text-center mt-3">
                Seçilen kategoriye ait bilgi bulunamadı.
              </p>
            )}
             <div className="">
              <p className="text-black text-center mt-5 mb-5"> {ReactHtmlParser(category.info)} </p>
            </div>
          </div>
        </div>
        <Spacing lg="65" md="10" />
        <Spacing lg="145" md="80" />
      </Layout>{" "}
    </>
  );
}

export default KategoriDetails;
