import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Cta from "../../components/Cta";
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading";
import Spacing from "../../components/Spacing";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
function slugify(text) {
  return text
    .toLowerCase()
    .replace(/ğ/g, "g")
    .replace(/ü/g, "u")
    .replace(/ş/g, "s")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ç/g, "c")
    .replace(/\s+/g, "-")
    .replace(/[^-\w]+/g, "")
    .replace(/-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}



export default function BlogDetails() {
  const location = useLocation();
  const [result, setResult] = useState({});
  useEffect(() => {
    const objName = location.pathname.split("/blog/")[1];
    axios
      .get("https://api.aynaart.com.tr/blog")
      .then((res) => {
        const blogData = res.data;
        const currentBlog = blogData.find(
          (blog) => slugify(blog.name) === slugify(objName)
        );
        setResult(currentBlog);
        localStorage.setItem("currentBlog", currentBlog);
        setResult(currentBlog);


      })
      .catch((err) => {});
  
  }, []);

  return (
    <>  
     {result.name && result.metaDesc && result.metaKeywords && (
    
        <Helmet>
          <title>{`Blog | ${result.name}`}</title>
          <meta name="description" content={result.metaDesc} />
          <meta name="keywords" content={result.metaKeywords} />
        </Helmet>
      )}
      <Layout>
        <PageHeading
          title={result.name}
          bgSrc="/images/blog_details_hero_bg.jpeg"
          pageLinkText={"BLOG"}
        />
        <Spacing lg="150" md="80" />
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="cs-post cs-style2">
                {result.img && (
                  <div className="cs-post_thumb cs-radius_15">
                    <img
                      src={`https://api.aynaart.com.tr/uploads/${result.img}`}
                      alt="Post"
                      className="w-100 cs-radius_15"
                    />
                  </div>
                )}
                <a
                  href="/"
                  style={{ color: "black", fontWeight: "bold" }}
                  className="cs-posted_by ms-4"
                >
                  {" "}
                  AYNAART
                </a>
                <div className="cs-post_info">
                  <div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font"></div>
                  <h2 className="cs-post_title">{result.name}</h2>
                  <p style={{ color: "black" }}>
                    {ReactHtmlParser(result.info)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
        <div className="container">
          <Cta
            title="Daha Fazla Bilgi İçin İletişime Geçin"
            btnText="İletişime Geçin"
            btnLink="/iletisim"
            bgSrc="/images/cta_bg.jpeg"
          />
        </div>
      </Layout>
    </>
  );
}
