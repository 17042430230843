import Button from '../Button';
import Button2 from '../Button/alternative';
import postData from "../../components/json/postData.json"
import Spacing from '../Spacing';
import ReactHtmlParser from "react-html-parser";

export default function PostStyle2({
  img1,
  title,
  date,
  category,
  categoryHref,
  link,
  pr,
  productData,
  id
}) {
  console.log(link);
  return (
    <div className="cs-post cs-style2">
      <a href={`/blog/${id}`} className="cs-post_thumb cs-radius_15 col-10">
        {img1 ? <img src={img1} alt="Post" className="w-100 cs-radius_15" /> : null}
      </a>
      <div className="cs-post_info">
        <div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
          {/* <span className="cs-posted_by">{date}</span> */}
          <a href={`/blog/${id}`} className="cs-post_avatar">
            {category}
          </a>
        </div>
        <h2 className="cs-post_title">
          <a href={`/blog/${id}`}>{title}</a>
        </h2>
        <div className="cs-post_sub_title">{ReactHtmlParser(pr)}</div>
        <Button2 btnLink={`/blog/${id}`} btnText="Daha Fazla" />
        <Spacing lg="40" md="0" />
      </div>
    </div>
  );
}
