import { Icon } from '@iconify/react';


export default function SocialWidget() {
  return (
    <div className="cs-social_btns cs-style1">
      {/* <Link href="/" className="cs-center">
        <Icon icon="fa6-brands:linkedin-in" />
      </Link>
      <Link href="/" className="cs-center">
        <Icon icon="fa6-brands:twitter" />
      </Link> */}
      <a  target='_blank'  href="https://www.facebook.com/profile.php?id=100063608101000" className="cs-center">
        <Icon icon="fa6-brands:facebook" />
      </a>
      <a target='_blank'  href="https://www.instagram.com/aynaart_/" className="cs-center">
        <Icon icon="fa6-brands:instagram" />
      </a>
      <a target='_blank'  aria-label="Chat on WhatsApp" href="https://wa.me/905537982306?text=Merhaba, bilgi almak istiyorum." className="cs-center whatsapp">
        <Icon className='wp_icon' icon="fa6-brands:whatsapp" />
      </a>
    </div>
  );
}
