import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading";
import Spacing from "../../components/Spacing";
import Card from "../../components/Card";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from 'react-helmet';

export default function UrunDetails() {
  const { urunAyrinti } = useParams();
  const [productDetails, setProductDetails] = useState(null);
  function removeTurkishChars(text) {
    const turkishChars = {
      'ğ': 'g',
      'Ğ': 'G',
      'ü': 'u',
      'Ü': 'U',
      'ş': 's',
      'Ş': 'S',
      'ı': 'i',
      'İ': 'I',
      'ö': 'o',
      'Ö': 'O',
      'ç': 'c',
      'Ç': 'C'
    };
  
    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, match => turkishChars[match]);
  }
  useEffect(() => {
    if (urunAyrinti) {
      // Replace spaces with hyphens in the product name
      const formattedUrunAyrinti = removeTurkishChars(urunAyrinti).replace(/\s+/g, '-').toLowerCase();

      axios
        .get("https://api.aynaart.com.tr/productaynaart")
        .then((response) => {
          const allCategories = response.data;
          // console.log("All Categories:", allCategories);

          // Find the selected category
          const selectedCategory = allCategories.find(category => {
            return category.category.products.some(product => removeTurkishChars(product.name).replace(/\s+/g, '-').toLowerCase() === formattedUrunAyrinti);
          });

          // console.log("Selected Category:", selectedCategory);

          // If the category is found, find the selected product within that category
          if (selectedCategory) {
            const productsInCategory = selectedCategory.category.products;
            // console.log("Product Names:", productsInCategory.map((product) => product.name));

            const foundProduct = productsInCategory.find(
              (product) => removeTurkishChars(product.name).replace(/\s+/g, '-').toLowerCase() === formattedUrunAyrinti
            );

            // console.log("Found Product:", foundProduct);

            if (foundProduct) {
              setProductDetails(foundProduct);
            }
          }
        })
        .catch((error) => {
          // console.error("Ürün bilgilerini çekerken hata oluştu:", error);
        });
    }
  }, [urunAyrinti]);


  return (
    <>
      {/* {console.log(productDetails.metaKeywords)} */}

      <Helmet>
        {productDetails && (
          <title>
          Aynaart | {productDetails.name || "Product Name Not Available"}
          </title>
        )}
        {productDetails && (
          <meta name="description" content={productDetails.metaDesc || "Default Description"} />
        )}
          {productDetails && (
          <meta name="keywords" content={productDetails.metaKeywords || "Default Keywords"} />
        )}
        {productDetails && (
          <link
          rel="canonical"
          href={`https://www.aynaart.com.tr/urunAyrinti/${(removeTurkishChars(productDetails.name) || "Product Name Not Available").replace(/\s+/g, '-').toLowerCase()}`}
        />
        
        )}
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Layout>
        <>
          {productDetails && (
            <PageHeading
              title={productDetails.name || "Product Name Not Available"}
              bgSrc="/images/blog_hero_bg.jpeg"
              pageLinkText={productDetails.name || "Product Name Not Available"}
            />
          )}
        </>

        <Spacing lg="150" md="80" />

        <div className="container aligner">
          {productDetails && (
            <div key={productDetails._id}>
              <div className="card-container">
                <div className="row d-flex justify-content-center">
                  {productDetails.img.map((image, index) => (
                    <div className="col-lg-4" key={index}>
                      <Card
                        key={index}
                        title={"" /* productDetails.name */}
                        src={`https://api.aynaart.com.tr/uploads/${image}`}
                        data={productDetails}
                        id={productDetails.name}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <p className="text-black text-center mt-3">
                {ReactHtmlParser(productDetails.info)}
              </p>
            </div>
          )}
        </div>

        <Spacing lg="65" md="10" />
        <Spacing lg="145" md="80" />
      </Layout>
    </>
  );
}
