// import Head from 'next/head';
import { Helmet } from "react-helmet";
import React from "react";
import Cta from "../../components/Cta";
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading";
import SectionHeading from "../../components/SectionHeading";
import Spacing from "../../components/Spacing";

export default function About() {
  return (
    <>
      <Helmet>
        <title>Aynaart | Hakkımızda</title>
        <meta name="description" content="Aynaların sıradan bir objeden daha fazlası olduğuna inanıyoruz. Her ayna, bir sanat eseri gibi özenle tasarlanır ve ruhunuza hitap etmek için yaratıcılığı yansıtır. Özgün tasarımlarımızla, mekanlarınıza karakter, tarz ve kişilik katmayı hedefliyoruz."/>
        <link
          rel="canonical"
          href={"https://www.aynaart.com.tr/hakkimizda"}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Layout>
        {/* Start Page Heading Section */}
        <PageHeading
          title="Hakkımızda"
          bgSrc="/images/blog_hero_bg.jpeg"
          pageLinkText="HAKKIMIZDA"
        />
        {/* End Page Heading Section */}

        {/* Start About Section */}
        <Spacing lg="150" md="80" />
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-7">
              <SectionHeading title="Hakkımızda" subtitle="Aynaart">
                <Spacing lg="30" md="20" />
                <p style={{ color: "#000000" }} className="cs-m0">
                  Biz, ayna sektöründe uzmanlaşmış bir işletmeyiz ve
                  müşterilerimize yüksek kaliteli ve estetik açıdan çarpıcı
                  aynalar sunmaktayız. Misyonumuz, evlerin, işyerlerinin veya
                  diğer mekanların iç mekanlarını güzelleştiren, tarzını
                  tamamlayan ve pratik çözümler sunmaktır.Müşteri memnuniyeti
                  bizim önceliğimizdir ve müşterilerimize mükemmel bir alışveriş
                  deneyimi sağlamak için çalışırız. Her müşterinin özel
                  ihtiyaçlarını anlamaya ve onlara en iyi aynaları sunmaya
                  odaklanırız. Ekibimiz, ayna tasarımında uzmanlaşmış yetenekli
                  ve deneyimli kişilerden oluşur. Kaliteli malzemeler ve özenli
                  üretim süreçleriyle, dayanıklı ve şık aynalar üretiriz.
                </p>
                <Spacing lg="30" md="30" />
                <div className="cs-separator cs-accent_bg"></div>
                <Spacing lg="25" md="40" />
              </SectionHeading>
            </div>
            <div className="col-lg-5 offset-xl-2">
              <img
                src="/images/hakkimizda1.jpg"
                alt="About"
                className="w-100 cs-radius_15 "
              />
              <Spacing lg="25" md="25" />
            </div>
            <div className="col-lg-7">
              <img
                src="/images/hakkimizda3.jpg" /*/images/ayna7.jpg*/
                alt="About"
                className="w-100 cs-radius_15 "
              />
              <Spacing lg="25" md="25" />
            </div>
            <div className="col-lg-5">
              <img
                src="/images/hakkimizda2.jpg" /*/ayna6.jpg   */
                alt="About"
                className="w-100 cs-radius_15 "
              />
              <Spacing lg="25" md="25" />
            </div>
          </div>
        </div>
        <Spacing lg="75" md="55" />
        {/* End About Section */}

        {/* Start Why Choose Section */}
        <Spacing lg="100" md="80" />
        <div className="container">
          <div className="row">
            <div className="col-xl-5 col-lg-6">
              <div className="cs-image_layer cs-style1">
                <div className="cs-image_layer_in">
                  <img
                    src="/images/aynakonsol.jpg"
                    alt="About"
                    className=" cs-radius_15"
                  />
                </div>
              </div>
              <Spacing lg="0" md="40" />
            </div>
            <div className="col-xl-5 offset-xl-1 col-lg-6 ">
              <SectionHeading title="Neden Biz" subtitle="Aynaart">
                <Spacing lg="30" md="20" />
                <p style={{ color: "#000000" }} className="cs-m0">
                  Bizim amacımız, evlerin, ofislerin veya diğer mekanların
                  dekorasyonunu tamamlayan estetik ve işlevsel aynaları
                  sağlamaktır. Müşteri memnuniyeti bizim önceliğimizdir ve her
                  bir müşterinin ihtiyaçlarına özel çözümler sunmak için çaba
                  gösteririz. Uzman ekibimiz, yaratıcı ve kaliteli ayna
                  tasarımlarıyla müşterilerimizin beklentilerini aşmayı amaçlar.
                  Ayrıca, müşterilerimize rekabetçi fiyatlarla geniş bir ürün
                  yelpazesi sunarak, farklı zevklere ve bütçelere uygun
                  seçenekler sunmaktayız.
                </p>
                <Spacing lg="15" md="15" />
                <p style={{ color: "#000000" }} className="cs-m0">
                  İşletmemiz, güvenilirlik, kalite ve müşteri odaklı hizmet
                  anlayışıyla bilinir. Aynalarımızın dayanıklılığı ve
                  estetiğiyle müşterilerimize uzun süreli kullanım sağlamayı
                  hedeflemekteyiz. İşletmemiz, şeffaf iletişim ve müşteri
                  taleplerine duyarlılıkla çalışarak müşterilerimizin
                  beklentilerini karşılamayı ve ayna ihtiyaçlarına en iyi
                  çözümleri sunmayı amaçlar.
                </p>
                <Spacing lg="30" md="30" />
                <div className="cs-separator cs-accent_bg"></div>
                <Spacing lg="25" md="0" />
              </SectionHeading>
            </div>
          </div>
        </div>
        {/* End Why Choose Section */}

        {/* Start CTA Section */}
        <Spacing lg="150" md="80" />
        <div className="container">
          <Cta
            title="Daha Fazla Bilgi İçin İletişime Geçin"
            btnText="İletişime Geçin"
            btnLink="/iletisim"
            bgSrc="/images/cta_bg.jpeg"
          />
        </div>
        {/* End CTA Section */}
      </Layout>
    </>
  );
}
