/* import product from "../../components/json/product.json" */

export default function PageHeading({ title, bgSrc, pageLinkText }) {
  return (
    <div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg_second"
      style={{ backgroundImage: `url(${bgSrc})` }}
    >
      <div className="container">
        <div className="cs-page_heading_in">
          <h1 className="cs-page_title cs-font_50 cs-white_color">{title}</h1>
          <ol className="breadcrumb text-uppercase">
            <li className="breadcrumb-item">
              <a href="/" style={{ color: "white" }}>
                ANASAYFA
              </a>
            </li>
            <li className="breadcrumb-item active">
              {pageLinkText}
            </li>
           
          </ol>
        </div>
      </div>
    </div>
  );
}
