// import Head from "next/head";
import { Helmet } from 'react-helmet';

import React, { useState, useEffect } from "react";
import Cta from "../../components/Cta";
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading";
import PostStyle2 from "../../components/Post/PostStyle2";
import Spacing from "../../components/Spacing";
import SectionHeading from "../../components/SectionHeading";
import axios from "axios";
function slugify(text) {
  return text
    .toLowerCase()
    .replace(/ğ/g, "g")
    .replace(/ü/g, "u")
    .replace(/ş/g, "s")
    .replace(/ı/g, "i")
    .replace(/ö/g, "o")
    .replace(/ç/g, "c")
    .replace(/\s+/g, "-")
    .replace(/[^-\w]+/g, "")
    .replace(/-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
}

export default function Blog({ blogData }) {
  const [blogDataState, setBlogDataState] = useState(blogData);

  useEffect(() => {
    axios
      .get("https://api.aynaart.com.tr/blog")
      .then((response) => {
        console.log("API Response:", response.data);
        setBlogDataState(response.data);
      })
      .catch((error) => {
        console.error("Veri çekme hatası:", error);
        setBlogDataState([]);
      });
  }, []);

  return (
    <>
    
      <Helmet>
        <title>Aynaart | Blog</title>
        <meta name="description" content="Aynaart blog page" />
        <link
          rel="canonical"
          href={"https://www.aynaart.com.tr/blog"}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Layout>
        <PageHeading
          title="Blog"
          bgSrc="/images/blog_hero_bg.jpeg"
          pageLinkText="BLOG"
        />
        <Spacing lg="150" md="80" />
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              {blogDataState && blogDataState.length > 0 ? (
                blogDataState.map((item, index) => (
                  <div key={item.blog_no}>
                    {item.img ? (
                      <div>
                        <a href={`/blog/${slugify(item.name)}`}>
                          {" "}
                          <img
                            src={`https://api.aynaart.com.tr/uploads/${item.img}`}
                            alt={item.name}
                          />
                        </a>
                        <a href={`/blog/${slugify(item.name)}`}>
                          <PostStyle2
                            id={slugify(item.name)}
                            title={item.name}
                            pr={item.info?.slice(0, 400) + "..."}
                            link={`/blog/${slugify(item.name)}`}
                          />
                        </a>
                      </div>
                    ) : (
                      <div>
                        <PostStyle2
                          id={slugify(item.name)}
                          title={item.name}
                          pr={item.info?.slice(0, 400) + "..."}
                          link={`/blog/${slugify(item.name)}`}
                        />
                      </div>
                    )}
                    <Spacing lg="40" md="0" />
                  </div>
                ))
              ) : (
                <div>
                  <SectionHeading
                    title="Henüz paylaşılmış blog bulunmamaktadır"
                    subtitle="Aynaart"
                  >
                    <Spacing lg="30" md="20" />
                    <p style={{ color: "#000000" }} className="cs-m0">
                      Henüz blog sayfamız aktif değil, ancak en yakın zamanda
                      ilgi çekici ve bilgilendirici içeriklerle dolu bir blog
                      deneyimi sunmayı planlıyoruz. Aynaların yaşamımızdaki
                      rolüne dair merak ettiğiniz pek çok konuyu ele alacağımız
                      blog yazılarımızla, estetik ve işlevselliğin bir araya
                      geldiği bu özel parçaların farklı yönlerini
                      keşfedeceksiniz.
                    </p>
                    <Spacing lg="30" md="30" />
                    <div className="cs-separator cs-accent_bg"></div>
                    <Spacing lg="25" md="40" />
                  </SectionHeading>
                </div>
              )}
            </div>
            <div className="col-xl-3 col-lg-4 offset-xl-1">
              <Spacing lg="0" md="80" />
            </div>
          </div>
        </div>
        {/* buse */}
        <Spacing lg="90" md="80" />
        <div className="container">
          <Cta
            title="Daha Fazla Bilgi İçin İletişime Geçin"
            btnText="İletişime Geçin"
            btnLink="/iletisim"
            bgSrc="/images/cta_bg.jpeg"
          />
        </div>
      </Layout>
    </>
  );
}
