import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import PageHeading from "../components/PageHeading";
// import "../assets/styles/shortcode/header.scss"
const not_found = () => {
  return (
    <>
      <Helmet>
        <title>Aynaart | Sayfa Bulunamadı</title>
      </Helmet>

      <Layout>
        <PageHeading
          title="Sayfa Bulunamadı"
          bgSrc="/images/blog_hero_bg.jpeg"
          pageLinkText="404"
        />
        <div className="not_found">
          <h1># 404</h1>
          <h3>Sayfa Bulunamadı</h3>
        </div>
      </Layout>
    </>
  );
};

export default not_found;
