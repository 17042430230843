import React, { useEffect, useState } from "react";
// import { useRouter } from "next/router";
import SocialWidget from "../Widget/SocialWidget";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import DropDown from "./DropDown";
import axios from "axios";

export default function Header({ variant }) {
  const [products, setProducts] = useState([]);
  function slugify(text) {
    return text
      .toLowerCase()
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c")
      .replace(/\s+/g, "-") // Boşlukları tireye dönüştürür
      .replace(/[^-\w]+/g, "") // Alfanumerik olmayan karakterleri kaldırır
      .replace(/-+/g, "-") // Birden fazla tireyi tek bir tireye dönüştürür
      .replace(/^-+/, "") // Başlangıçtaki tireleri kaldırır
      .replace(/-+$/, ""); // Sondaki tireleri kaldırır
  }

  function removeTurkishChars(text) {
    const turkishChars = {
      'ğ': 'g',
      'Ğ': 'G',
      'ü': 'u',
      'Ü': 'U',
      'ş': 's',
      'Ş': 'S',
      'ı': 'i',
      'İ': 'I',
      'ö': 'o',
      'Ö': 'O',
      'ç': 'c',
      'Ç': 'C'
    };
  
    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, match => turkishChars[match]);
  }
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [links, setLinks] = useState([]); // Verileri saklamak için bir state tanımlayın.

  // ...

  useEffect(() => {
    // Verileri çekmek için useEffect kullanın
    axios
      .get("https://api.aynaart.com.tr/productaynaart")
      .then((response) => {
        setProducts(response.data); // Çekilen verileri state'e kaydedin
      })
      .catch((error) => {
        console.error("Ürünleri çekerken hata oluştu:", error);
      });
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    });
  }, []);

  return (
    <>
      <header
        className={`cs-site_header cs-style1 text-uppercase ${
          variant ? variant : ""
        } cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}
      >
        <div className="cs-main_header">
          <div className="container">
            <div className="cs-main_header_in">
              <div className="cs-main_header_left">
                <a className="cs-site_branding" href="/">
                  <img src="/images/logo.png" alt="Logo" />
                </a>
              </div>
              <div className="cs-main_header_center">
                <div className="cs-nav cs-primary_font cs-medium">
                  <ul
                    className="cs-nav_list"
                    style={{ display: `${mobileToggle ? "block" : "none"}` }}
                  >
                    <li>
                      <a
                        href="/"
                        /*  style={{color:'#fff'}} */ onClick={() =>
                          setMobileToggle(false)
                        }
                      >
                        ANASAYFA
                      </a>
                    </li>
                    <li className="menu-item-has-children">
                      <a
                     
                        href="/urunler"
                        onClick={() => setMobileToggle(false)}
                        >
                        ÜRÜNLER
                      </a>
                          <DropDown>
                        <ul>
                          {products.length > 0 &&
                            products.map((item) => (
                              <div key={item._id}>
                                {item.category && item.category.name && (
                                  <a
                                    href={`/urunler/${removeTurkishChars(item.category.name).replace(/\s+/g, '-').toLowerCase()}`}


                                  >
                                    {item.category.name}
                                  </a>
                                )}
                              </div>
                            ))}
                        </ul>
                      </DropDown>
                    </li>
                    <li>
                      <a
                        href="/galeri"
                        onClick={() => setMobileToggle(false)}
                      >
                        GALERİ
                      </a>
                    </li>
                    <li>
                      <a
                        href="/hakkimizda"
                        onClick={() => setMobileToggle(false)}
                      >
                        HAKKIMIZDA
                      </a>
                    </li>
                    <li>
                      <a href="/blog" onClick={() => setMobileToggle(false)}>
                        BLOG
                      </a>
                    </li>
                    <li>
                      <a
                        href="/iletisim"
                        onClick={() => setMobileToggle(false)}
                      >
                        İLETİŞİM
                      </a>
                    </li>
                  </ul>
                  <span
                    className={
                      mobileToggle
                        ? "cs-munu_toggle cs-toggle_active"
                        : "cs-munu_toggle"
                    }
                    onClick={() => setMobileToggle(!mobileToggle)}
                  >
                    <span></span>
                  </span>
                </div>
              </div>
              <div className="cs-main_header_right">
                <div className="cs-toolbox">
                  <span
                    className="cs-icon_btn"
                    onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
                  >
                    <span className="cs-icon_btn_in">
                      <span />
                      <span />
                      <span />
                      <span />
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div
        className={
          sideHeaderToggle ? "cs-side_header active" : "cs-side_header"
        }
      >
        <button
          className="cs-close"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <div
          className="cs-side_header_overlay"
          onClick={() => setSideHeaderToggle(!sideHeaderToggle)}
        />
        <div className="cs-side_header_in">
          <div className="cs-side_header_shape" />
          <a className="cs-site_branding" href="/">
            <img src="/images/logokrem.png" alt="Logo" />
          </a>
          <div className="cs-side_header_box">
            <h2 className="cs-side_header_heading">
              Daha Fazla Bilgi İçin <br /> İletişime Geçin
            </h2>
          </div>
          <div className="cs-side_header_box">
            <ContactInfoWidget withIcon />
          </div>
          <div className="cs-side_header_box">
            <SocialWidget />
          </div>
        </div>
      </div>
    </>
  );
}
