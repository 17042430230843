import React from 'react';

export default function FunFact({ variant, title, subtitle, data }) {
  return (
    <div className={variant ? `cs-funfact_wrap ${variant}` : 'cs-funfact_wrap'}>
      <div
        className="cs-funfact_shape"
        style={{ backgroundImage: 'url(./images/funfact_shape_bg.svg)' }}
      />
      <div className="cs-funfact_left ">
        <div className="cs-funfact_heading white_text">
          <h2 className='white_text'>{title}</h2>
          <p>{subtitle}</p>
        </div>
      </div>

  
      {/* <div className="cs-funfact_right">
        <div className="cs-funfacts">
          {data.map((item, index) => (
            <div className="cs-funfact cs-style1" key={index}>
              <div className="cs-funfact_number cs-primary_font cs-semi_bold cs-primary_color">
                <span />
                {item.factNumber}
              </div>
              <div className="cs-funfact_text">
                <span className="cs-accent_color">+</span>
                <p>{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      </div> */}


    </div>
  );
}


 