import { Icon } from '@iconify/react';

export default function Button2({ btnLink, btnText, variant, icon }) {
  return (
    <a
      href={btnLink}
      className={variant ? `cs-text_btn_post_style_page ${variant}` : 'cs-text_btn_post_style_page'}
    >
      <>
        <span >{btnText}</span>
        {icon ? icon : <Icon /* style={{color:"#000"}}  */icon="bi:arrow-right" />}
      </>
    </a>
  );
}
