import parse from 'html-react-parser';
import Button from '../Button';
import VerticalLinks from '../VerticalLinks';

export default function Hero({
  title,
  subtitle,
  btnText,
  btnLink,
  scrollDownId,
  socialLinksHeading,
  heroSocialLinks,
  bgImageUrl,
}) {
 
  return (
    <div/*  cs-bg */
      className="cs-hero cs-style1 cs-fixed_bg cs-shape_wrap_1 "
      style={{ backgroundImage: `url(${bgImageUrl  })` }}
    >
      <div className="cs-shape_1" />
      <div className="cs-shape_1" />
      <div className="cs-shape_1" />
      <div className="container">
        <div className="cs-hero_text ">
          <h1 className="cs-hero_title">{parse(title)}</h1>
          <div className="cs-hero_info ">
            <div style={{color:'#fff' }} >
              <Button btnLink={btnLink} btnText={btnText} style={{color:'#fff'}} />
            </div>
            <div>
              <div className="cs-hero_subtitle " style={{color:'#fff'}} >{subtitle}</div>
            </div>
          </div>
        </div>
      </div>
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      <a href={scrollDownId} className="cs-down_btn">
        .
      </a>
    </div>
  );
}
