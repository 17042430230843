import { Icon } from "@iconify/react";
// import Head from 'next/head';
import { Helmet } from "react-helmet";

import React, { useState } from "react";
import Cta from "../../components/Cta";
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading";
import Portfolio from "../../components/Portfolio";
import SectionHeading from "../../components/SectionHeading";
import Spacing from "../../components/Spacing";

export default function PortfolioPage() {
  const [active, setActive] = useState("all");
  const [itemShow, setItemShow] = useState(7);
  const portfolioData = [
    {
      title: "Dresuar",

      src: "/images/ayna1.jpg",
      category: "dresuarlar",
    },
    {
      title: "Banyo Aynası",

      src: "/images/aynabanyo.jpg",
    },
    {
      title: "Boy Aynası",
      src: "/images/aynaboy.jpg",
      category: "boy_aynalari",
    },
    {
      title: "Pencere Model Ayna",

      src: "/images/ayna13.jpg",
    },
    {
      title: "Dresuar",

      src: "/images/ayna5.jpg",
      category: "dresuarlar",
    },
    {
      title: "Konsol Üstü Ayna",

      src: "/images/aynakonsol.jpg",
      category: "konsol_ustu",
    },
    {
      title: "Konsol Üstü Ayna",

      src: "/images/ayna6.jpg",
      category: "konsol_ustu",
    },
    {
      title: "Özel Tasarım Ayna",

      src: "/images/ayna7.jpg",
      category: "ozel_tasarim",
    },
    {
      title: "Özel Tasarım Ayna",

      src: "/images/aynaozel.jpg",
      category: "ozel_tasarim",
    },
    {
      title: "Dresuar",

      src: "/images/ayna8.jpg",
      category: "dresuarlar",
    },
  ];
  const categoryMenu = [
    {
      title: "Konsol Üstü Aynalar",
      category: "konsol_ustu",
    },
    {
      title: "Dresuarlar",
      category: "dresuarlar",
    },
    {
      title: "Boy Aynaları",
      category: "boy_aynalari",
    },
    {
      title: "Özel Tasarım Aynalar",
      category: "ozel_tasarim",
    },
  ];
  return (
    <>
      <Helmet>
        <title>Aynaart | Galeri</title>
        <meta
          name="description"
          content="Aynaların sıradan bir objeden daha fazlası olduğuna inanıyoruz. Her ayna, bir sanat eseri gibi özenle tasarlanır ve ruhunuza hitap etmek için yaratıcılığı yansıtır. Özgün tasarımlarımızla, mekanlarınıza karakter, tarz ve kişilik katmayı hedefliyoruz."
        />
        <link rel="canonical" href={"https://www.aynaart.com.tr/galeri"} />
        <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Layout>
        <PageHeading
          title="Galeri"
          bgSrc="/images/blog_hero_bg.jpeg"
          pageLinkText="GALERİ"
        />
        <Spacing lg="145" md="80" />
        <div className="container">
          <div className="cs-portfolio_1_heading">
            <SectionHeading title="Koleksiyonumuz" subtitle="Galerimiz" />
            <div className="cs-filter_menu cs-style1">
              <ul className="cs-mp0 cs-center">
                <li className={active === "all" ? "active" : ""}>
                  <span onClick={() => setActive("all")}>Hepsi</span>
                </li>
                {categoryMenu.map((item, index) => (
                  <li
                    className={active === item.category ? "active" : ""}
                    key={index}
                  >
                    <span onClick={() => setActive(item.category)}>
                      {item.title}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <Spacing lg="90" md="45" />
          <div className="row">
            {portfolioData.slice(0, itemShow).map((item, index) => (
              <div
                className={`${
                  index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
                } ${
                  active === "all"
                    ? ""
                    : !(active === item.category)
                    ? "d-none"
                    : ""
                }`}
                key={index}
              >
                <Portfolio
                  title={item.title}
                  subtitle={item.subtitle}
                  href={item.href}
                  src={item.src}
                  variant="cs-style1 cs-type1"
                />
                <Spacing lg="25" md="25" />
              </div>
            ))}
          </div>

          <div className="text-center">
            {portfolioData.length <= itemShow ? (
              ""
            ) : (
              <>
                <Spacing lg="65" md="40" />
                <span
                  className="cs-text_btn"
                  onClick={() => setItemShow(itemShow + 3)}
                >
                  <span style={{ color: "black" }}>Daha Fazla</span>
                  <Icon style={{ color: "black" }} icon="bi:arrow-right" />
                </span>
              </>
            )}
          </div>
        </div>

        <Spacing lg="90" md="80" />
        <div className="container">
          <Cta
            title="Daha Fazla Bilgi İçin İletişime Geçin"
            btnText="İletişime Geçin"
            btnLink="/iletisim"
            bgSrc="/images/cta_bg.jpeg"
          />
        </div>
      </Layout>
    </>
  );
}
