// import Head from "next/head";
import { Helmet } from "react-helmet";

import React, { useEffect, useState } from "react";
import Card from "../../components/Card";
import Cta from "../../components/Cta";
import IconBox from "../../components/IconBox";
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading";
import SectionHeading from "../../components/SectionHeading";
import Spacing from "../../components/Spacing";
import axios from "axios"; 
import ReactHtmlParser from "react-html-parser";
import "bootstrap/dist/css/bootstrap.min.css";

export default function Service() {
  const [products, setProducts] = useState([]); 
  useEffect(() => {

    axios
      .get("https://api.aynaart.com.tr/productaynaart")
      .then((response) => {
        setProducts(response.data);
        console.log("res" + response.data); // Çekilen verileri state'e kaydedin
      })
      .catch((error) => {
        console.error("Ürünleri çekerken hata oluştu:", error);
      });
  }, []);
  function removeTurkishChars(text) {
    const turkishChars = {
      'ğ': 'g',
      'Ğ': 'G',
      'ü': 'u',
      'Ü': 'U',
      'ş': 's',
      'Ş': 'S',
      'ı': 'i',
      'İ': 'I',
      'ö': 'o',
      'Ö': 'O',
      'ç': 'c',
      'Ç': 'C'
    };
  
    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, match => turkishChars[match]);
  }

  return (
    <>
      <Helmet>
        <title>Aynaart | Kategoriler</title>
        <meta name="description" content="Aynaların sıradan bir objeden daha fazlası olduğuna inanıyoruz. Her ayna, bir sanat eseri gibi özenle tasarlanır ve ruhunuza hitap etmek için yaratıcılığı yansıtır. Özgün tasarımlarımızla, mekanlarınıza karakter, tarz ve kişilik katmayı hedefliyoruz."/>
        <link
          rel="canonical"
          href={"https://www.aynaart.com.tr/urunler"}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Layout>
        <PageHeading
          title="Kategoriler"
          bgSrc="/images/blog_hero_bg.jpeg"
          pageLinkText="KATEGORİLER"
        />
        <Spacing lg="150" md="80" />

        <div className="cs-shape_wrap_4">
          <div className="cs-shape_4"></div>
          <div className="cs-shape_4"></div>

          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-xl-3">
                <SectionHeading title="Kategoriler" subtitle="Aynaart" />
                <Spacing lg="90" md="45" />
              </div>
              <div className="col-12 col-lg-9">
                <div className="row">
                  {products.length > 0 &&
                    products.map((item) => (
                      <div className="col-md-6 " key={item._id}>
                        {item.category && item.category.name && (
                          <div className="d-flex justify-content-center align-items-center flex-column">
                            <div className="col-12">
                              <Card
                                title={item.category.name}
                                // info={item.category.info}
                                link={`/urunler/${removeTurkishChars(item.category.name).replace(/\s+/g, '-').toLowerCase()}`}
                                src={`https://api.aynaart.com.tr/uploads/${item.category.img}`}
                                data={item}
                                id={item._id}
                              />
                            </div>
                            {/* <div className="col-12 text-black d-flex justify-content-center align-items-center p-3">
                              {ReactHtmlParser(item.category.info)}
                            </div> */}
                          </div>
                        )}
                        <Spacing lg="30" md="30" />
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Spacing lg="150" md="80" />

        <div className="container">
          <SectionHeading
            title="Mekanlarınızı Benzersiz Kılın"
            subtitle="Aynaart"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
          <div className="row">
            <div className="col-lg-4">
              <IconBox
                icon="/images/icons/service_icon_1.svg"
                title="İç Mekanlarınızı Tamamlayın"
                subtitle="Aynaart olarak, aynaların gücünü ve dekoratif potansiyelini anlıyoruz. Her bir ayna, zerafeti ve işlevselliği bir araya getiren özenle seçilmiş malzemelerden üretilir. Tarzınızı yansıtan ve iç mekanınızı tamamlayan geniş bir ürün yelpazesi sunuyoruz."
              />
              <Spacing lg="30" md="30" />
            </div>
            <div className="col-lg-4">
              <IconBox
                icon="/images/icons/service_icon_2.svg"
                title="Müşteri Odaklı Hizmet"
                subtitle="Müşteri memnuniyeti odaklı çalışıyoruz ve her bir müşterimize kişisel ve profesyonel hizmet sunuyoruz. Size en uygun aynayı bulmanıza yardımcı olmak için uzman ekibimiz her zaman hazır. İhtiyaçlarınızı anlıyor ve bütçenize uygun seçenekler sunuyoruz."
              />
              <Spacing lg="30" md="30" />
            </div>
            <div className="col-lg-4">
              <IconBox
                icon="/images/icons/service_icon_3.svg"
                title="Kendinize Özel Bir Ayna Keşfi"
                subtitle="Hemen keşfetmeye başlayın ve sizin için mükemmel olan aynayı bulun. Sizleri ağırlamaktan mutluluk duyuyoruz."
              />
              <Spacing lg="30" md="30" />
            </div>
          </div>
        </div>
        <Spacing lg="145" md="80" />

        <div className="container">
          <Cta
            title="Daha Fazla Bilgi İçin İletişime Geçin"
            btnText="İletişime Geçin"
            btnLink="/iletisim"
            bgSrc="/images/cta_bg.jpeg"
          />
        </div>
      </Layout>
    </>
  );
}
