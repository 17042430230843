import { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
// import Head from "next/head";
import { Helmet } from 'react-helmet';
import React from "react";
import Layout from "../../components/Layout";
import PageHeading from "../../components/PageHeading/index";
import SectionHeading from "../../components/SectionHeading";
import Spacing from "../../components/Spacing";
import ContactInfoWidget from "../../components/Widget/ContactInfoWidget";
import { useRef } from "react";
import axios from "axios";
import CryptoJS from "crypto-js";
import { v4 as uuidv4 } from "uuid";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";
// import WhatsAppChatline from "../components/Widget/WhatsAppChatline";     
// import ReCAPTCHA from "react-google-recaptcha";


export default function Contact() {
 /*  const contactForm = useRef();
  const [isSuccess, setIsSuccess] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const FD = new FormData(contactForm.current);
    FD.append("ownerName", "aynaartwebcontact@gmail.com");
    FD.append("ownerPassword", "sathqbxwznlapokm");
    FD.append("to", "aynaartt@gmail.com");
    FD.append("host", "smtp.gmail.com");
    FD.append("service", "gmail");

    try {
      const res = await axios.post(process.env.EMAIL_SERVER, FD, {
        auth: {
          username: "OSICrew_Node_Mailler",
          password: "1786oSi?173",
        },
      });
      if (res) {
        console.log(res)
        setIsSuccess(true);
        Swal.fire({
          title: "İşlem Başarılı",
          text: "Mesajınız başarıyla gönderilmiştir",
          icon: "success",
          confirmButtonText: "Tamam",
          confirmButtonColor: "#2ED36A",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Hata!",
        text: "Lütfen tekrar deneyiniz",
        confirmButtonText: "Tamam",
        confirmButtonColor: "#F94741",
      });
    }
  }; */



  
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);
  // const [message, setMessage] = useState("");
  const handleSubmit = async (e) => {
    e.preventDefault();

const encryptedText = CryptoJS.AES.encrypt(
        uuidv4(),
 process.env.SSK  
        ).toString();
    /*  const handleSubmit = async (event) => {
    event.preventDefault(); 
    const FD = new FormData(form.current);*/

    const FD = new FormData(form.current);
    FD.append("accessToken", encryptedText);

    const obj = {
      message: FD.get("message"),
      userInfo: FD.get("userInfo"), 
      from: FD.get("from"),
      subject: FD.get("subject"),
      tel: FD.get("tel"),
      ownerName: "aynaartwebcontact@gmail.com",
      to: "aynaartt@gmail.com",
      host: "smtp.gmail.com",
      service: "gmail",
      accessToken:encryptedText
    };
    try {

      

      const res = await axios.post(
        "https://nodemailer.3wweb.org/",
        obj,
        /* await axios.post("https://nodemailer.enesatalay.com/", obj, */ {
          auth: {
            username: "OSICrew_Node_Mailler",
            password: "1786oSi?173",
          },
        }
      );
      if (res) {
        console.log(res);
        setIsSuccess(true);
        Swal.fire({
          title: "İşlem Başarılı",
          text: "Mesajınız başarıyla gönderilmiştir",
          icon: "success",
          confirmButtonText: "Tamam",
          confirmButtonColor: "#2ED36A",
        });
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Hata!",
        text: "Lütfen tekrar deneyiniz",
        confirmButtonText: "Tamam",
        confirmButtonColor: "#F94741",
      });
    }
  };
  return (
    <>

        <Helmet>
        <title>Aynaart | İletişim</title>
        <meta name="description" content="Aynaların sıradan bir objeden daha fazlası olduğuna inanıyoruz. Her ayna, bir sanat eseri gibi özenle tasarlanır ve ruhunuza hitap etmek için yaratıcılığı yansıtır. Özgün tasarımlarımızla, mekanlarınıza karakter, tarz ve kişilik katmayı hedefliyoruz."/>
        <link
          rel="canonical"
          href={"https://www.aynaart.com.tr/iletisim"}
        />
         <link rel="icon" href="/favicon.ico" />
      </Helmet>
      <Layout>
        <PageHeading
          title="İletişim"
          bgSrc="/images/contact_hero_bg.jpeg"
          pageLinkText="İLETİŞİM"
        />
        <Spacing lg="150" md="80" />
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <SectionHeading title="İletişime Geçin" subtitle="Aynaart" />
              <Spacing lg="55" md="30" />
              <ContactInfoWidget withIcon />
              <Spacing lg="0" md="50" />
            </div>
            <div className="col-lg-6">
              <form ref={form} className="row" onSubmit={handleSubmit}>
                <div className="col-sm-6">
                  <label className="cs-primary_color">Ad Soyad*</label>
                  <input
                    type="text"
                    name="userInfo"
                    required
                    className="cs-form_field"
                    style={{ color: "black" }}
                  />
                  <Spacing lg="20" md="20" />
                </div>
                <div className="col-sm-6">
                  <label className="cs-primary_color">E-posta*</label>
                  <input
                    type="text"
                    required
                    name="from"
                    className="cs-form_field"
                    style={{ color: "black" }}
                  />
                  <Spacing lg="20" md="20" />
                </div>
                <div className="col-sm-6">
                  <label className="cs-primary_color">Telefon No*</label>
                  <input
                    type="tel"
                    name="tel"
                    required
                    className="cs-form_field"
                    pattern="[0-9]{1}[0-9]{3}[0-9]{3}[0-9]{2}[0-9]{2}"
                    maxLength={11}
                    minLength={11}
                    style={{ color: "black" }}
                  />
                  <label style={{ color: "gray", fontSize: "13px" }}>
                    Örnek:05554443322
                  </label>
                  <Spacing lg="20" md="20" />
                </div>
                <div className="col-sm-6">
                  <label className="cs-primary_color">Konu*</label>
                  <input
                    name="subject"
                    type="text"
                    required
                    className="cs-form_field"
                    style={{ color: "black" }}
                  />
                  <Spacing lg="20" md="20" />
                </div>

                <div className="col-sm-12">
                  <label className="cs-primary_color">Mesaj*</label>
                  <textarea
                    name="message"
                    required
                    cols="30"
                    rows="7"
                    className="cs-form_field"
                    style={{ color: "black" }}
                  ></textarea>
                  <Spacing lg="25" md="25" />
                </div>
            {/*     <ReCAPTCHA
                sitekey="6LfCXcIoAAAAAIi488_0qRbQsvY7vmnPyyjQv7_o"
                // onChange={onChange}
                required
                theme="light"
              /> */}
                {isSuccess ? null : (
                  <div className="col-sm-12 btnSubmit">
                    <button
                      className="cs-btn cs-style1"
                      type="submit"
                      onSubmit={handleSubmit}
                    >
                      <span>Gönder</span>
                      <Icon icon="bi:arrow-right" />
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
        <Spacing lg="150" md="80" />
        <div className="cs-google_map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3058.3047814391803!2d32.89726007571928!3d39.95693797151723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d3530ac5d7c605%3A0x36982098bd273f0e!2sAyna%20Art!5e0!3m2!1str!2str!4v1689180299149!5m2!1str!2str"
            allowFullScreen
            title="Google Map"
          ></iframe>
        </div>
        <Spacing lg="50" md="40" />
      </Layout>
    </>
  );
}
