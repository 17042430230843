import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // Kütüphane için varsayılan stilleri içe aktarın

export default function Portfolio({ href, src, title, subtitle, variant }) {
  const [lightboxOpen, setLightboxOpen] = useState(false);

  const openLightbox = () => {
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };

  return (
    <div className={`cs-portfolio cs-bg ${variant ? variant : 'cs-style1'}`}>
      <>
        <div className="cs-portfolio_hover" onClick={openLightbox} />
        <div
          className="cs-portfolio_bg cs-bg_second "
          style={{ backgroundImage: `url("${src}")` }}
        />
        <div className="cs-portfolio_info">
          <div className="cs-portfolio_info_bg cs-accent_bg" />
          <h2 className="cs-portfolio_title">{title}</h2>
        </div>
        {lightboxOpen && (
          <Lightbox
            mainSrc={src}
            onCloseRequest={closeLightbox}
          />
        )}
      </>
    </div>
  );
}
