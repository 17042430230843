import React, { useEffect, useState } from "react";

import ContactInfoWidget from "../Widget/ContactInfoWidget";
import MenuWidget from "../Widget/MenuWidget";
import SocialWidget from "../Widget/SocialWidget";
import TextWidget from "../Widget/TextWidget";
import axios from "axios";

export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const [products, setProducts] = useState([]);
  function slugify(text) {
    return text
      .toLowerCase()
      .replace(/ğ/g, "g")
      .replace(/ü/g, "u")
      .replace(/ş/g, "s")
      .replace(/ı/g, "i")
      .replace(/ö/g, "o")
      .replace(/ç/g, "c")
      .replace(/\s+/g, "-") // Boşlukları tireye dönüştürür
      .replace(/[^-\w]+/g, "") // Alfanumerik olmayan karakterleri kaldırır
      .replace(/-+/g, "-") // Birden fazla tireyi tek bir tireye dönüştürür
      .replace(/^-+/, "") // Başlangıçtaki tireleri kaldırır
      .replace(/-+$/, ""); // Sondaki tireleri kaldırır
  }

  function removeTurkishChars(text) {
    const turkishChars = {
      'ğ': 'g',
      'Ğ': 'G',
      'ü': 'u',
      'Ü': 'U',
      'ş': 's',
      'Ş': 'S',
      'ı': 'i',
      'İ': 'I',
      'ö': 'o',
      'Ö': 'O',
      'ç': 'c',
      'Ç': 'C'
    };
  
    return text.replace(/[ğüşıöçĞÜŞİÖÇ]/g, match => turkishChars[match]);
  }
  const [isSticky, setIsSticky] = useState(false);
  const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
  const [mobileToggle, setMobileToggle] = useState(false);
  const [links, setLinks] = useState([]); // Verileri saklamak için bir state tanımlayın.

  // ...

  useEffect(() => {
    // Verileri çekmek için useEffect kullanın
    axios
      .get("https://api.aynaart.com.tr/productaynaart")
      .then((response) => {
        setProducts(response.data); // Çekilen verileri state'e kaydedin
      })
      .catch((error) => {
        console.error("Ürünleri çekerken hata oluştu:", error);
      });
  }, []);

  return (
    <footer className="cs-fooer">
      <div className="cs-fooer_main">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="cs-footer_item">
                <TextWidget
                  logoSrc="/images/logokrem.png"
                  logoAlt="Logo"
                  /*   text=" Evinizin veya iş yerinizin atmosferini dönüştürmek, tarzınızı tamamlamak veya herhangi bir projenize destek olmak için aynalarımızla buradayız. Size uygun çözümler ve profesyonel hizmet için bize ulaşmaktan çekinmeyin. " */
                />
                <SocialWidget />
              </div>
            </div>
            <div
              style={{ paddingRight: "0" }}
              className="col-lg-4 col-sm-6 d-flex footer_product"
            >
              <div className="cs-footer_item col-">
                <p>
                  <b>Ürünlerimiz</b>
                </p>
                {products.length > 0 &&
                  products.map((item) => (
                    <div key={item._id}>
                      {item.category && item.category.name && (
                        <a href={`/urunler/${removeTurkishChars(item.category.name).replace(/\s+/g, '-').toLowerCase()}`}>
                          {item.category.name}
                        </a>
                      )}
                    </div>
                  ))}
              </div>
            </div>

            <div className="col-lg-4 col-sm-6">
              <div className="cs-footer_item">
                <ContactInfoWidget title="Bizimle İletişime Geçin" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cs-bottom_footer">
          <div className="cs-bottom_footer_left">
            <div className="cs-copyright">Copyright © 2023 <a href="https://osicrew.com/" target="_blank">Osicrew</a></div>
          </div>
          {/*  <div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=" cs-style2" />
          </div> */}
        </div>
      </div>
    </footer>
  );
}
