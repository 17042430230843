

import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../src/pages/home/index";
import Contact from "../src/pages/contact/index";
import About from "../src/pages/about/index";
import Blog from "./pages/blog";
import Category from "./pages/products";
// Import CategoryDetail if needed
import CategoryDetail from "./pages/products/kategoriAyrinti";
import Gallery from "./pages/gallery";
import axios from "axios";
import "./assets/styles/globals.scss";
import KategoriDetails from "./pages/products/kategoriAyrinti";
import BlogDetails from "./pages/blog/blogName";
import UrunDetails from "./pages/products/urunAyrinti";
import NotFound from "./pages/not_found";

function App() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    // Fetch your products from the server
    const fetchProducts = async () => {
      try {
        const response = await axios.get(
          "https://api.aynaart.com.tr/productaynaart"
        );
        setProducts(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/anasayfa" element={<Home />} />
        <Route path="/urunler" element={<Category />} />
        <Route path="/urunler/:kategoriAyrinti" element={<KategoriDetails />} />
        <Route path="/urunAyrinti/:urunAyrinti" element={<UrunDetails />} />
        <Route path="/galeri" element={<Gallery />} />
        <Route path="/hakkimizda" element={<About />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:blogName" element={<BlogDetails />} />
        <Route path="/iletisim" element={<Contact />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default App;
