
export default function Card({ title, link, src, alt, data, info }) {
  if (link) {
    // Render the Link component if the 'link' prop is provided
    return (
      <a className="cs-card cs-style1" href= {`${link}`} >
        <img src={src} alt={alt} />
        <div className="cs-card_overlay" />
        <div className="cs-card_info">
          <span className=" cs-hover_layer3 " />
          {/* cs-accent_bg */}
          <h2 className="cs-card_title card_title_bg">{title}</h2>
        </div>
      </a>
    );
  } else {
    // Render the card without a Link if 'link' prop is not provided
    return (
      <div className="cs-card cs-style1">
        <img src={src} alt={alt} />
        <div className="cs-card_overlay" />
        <div className="cs-card_info">
          <span className=" cs-hover_layer3 " />
          {/* cs-accent_bg */}
          <h2 className="cs-card_title ">{title}</h2>
        </div>
      </div>
    );
  }
}
