import React from 'react'
import { Icon } from '@iconify/react';

export default function ContactInfoWidget({withIcon, title}) {
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <ul className="cs-menu_widget cs-style1 cs-mp0">
       <a href="tel:+905537982306">
        <li className='contactHref' style={{color:'black',marginBottom:'20px'}}>
          {withIcon?<span className='cs-accent_color'><Icon icon="material-symbols:add-call-rounded" /></span>:''}
          +90 553 798 23 06
        </li>
        </a> 
        <a href="mailto:aynaartt@gmail.com">
        <li className='contactHref' style={{color:'black',marginBottom:'20px'}}>
          {withIcon?<span className='cs-accent_color'><Icon icon="mdi:envelope" /></span>:''}
          aynaartt@gmail.com
        </li>
        </a>
        <li style={{color:'black'}}>
         <a href='https://maps.app.goo.gl/ndmLUMYyDZGM2XfSA'>
         {withIcon?<span className='cs-accent_color'><Icon icon="mdi:map-marker" /></span>:''}
          Ulubey Mah. Taştop Sk. 72/A <br/>Siteler, Ankara, Turkey
          </a> 
        </li>
      </ul>
    </>
  )
}
