import React from 'react'
import parse from 'html-react-parser';
import Button from '../Button'
import Spacing from '../Spacing'

export default function SectionHeading({title, subtitle, btnLink, btnText, variant, children}) {
  return (
    <div className={variant ? `cs-section_heading ${variant}` : `cs-section_heading cs-style1`}>
      <h3 className="cs-section_subtitle">{parse(subtitle)}</h3>
      <h2 className="cs-section_title black_text" style={{color:'#000'}} >{(title)}</h2>
      {children}
      {btnText && (
        <>
          <Spacing lg='45' md='20'/>
          <Button className="black_text" style={{color:'#000'}} btnLink={btnLink} btnText={btnText}/>
        </>
      )}
    </div>
  )
}
